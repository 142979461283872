<template>
    <footer>
        <p>Copyright &copy; 2021 Vintko</p>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>

<style scoped>
    footer {
        padding: 4em 0 .5em;
    }

    p {
        margin: 0;
        padding: 0;
        text-align: center;
    }
</style>